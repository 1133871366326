<template>
  <validation-observer ref="businessRules">
    <b-row>
      <b-col cols="12">
        <b-card title="Business Details">
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Business Name"
                label-for="v-eventname"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business Name"
                  rules="required"
                >
                  <b-form-input
                    id="v-eventname"
                    v-model="name"
                    placeholder="Electronic Powerhouse"
                    type="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Enabled"
                label-for="v-enabled"
              >
                <b-form-checkbox
                  id="v-enabled"
                  v-model="enabled"
                  class="custom-control-info"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-success"
            class="mt-0 mt-md-2 float-right"
            :disabled="loading"
            @click.prevent="validationForm"
          >
            <b-spinner
              small
              :hidden="isLoading"
            />
            <feather-icon
              icon="CheckIcon"
              class="mr-25"
            />
            <span>Update</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(234, 84, 85, 0.15)'"
            variant="outline-danger"
            class="mt-0 mt-md-2 mr-2 float-right"
            :disabled="loading"
            @click="cancel()"
          >
            <feather-icon
              icon="XIcon"
              class="mr-25"
            />
            <span>Cancel</span>
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BCard,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@/@core/mixins/ui/transition'
import store from '@store';

import axios from 'axios';
import * as api from '@api';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BCard,
    BButton,
    BSpinner,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      name: '',
      enabled: true,
      loading: false,
      required,
    }
  },
  async created() {
    this.getBusinessData();
  },
  computed: {
    isLoading() {
      return !this.loading;
    },
  },
  methods: {
    async cancel() {
      this.$router.go(-1);
    },
    validationForm() {
      this.$refs.businessRules.validate().then(success => {
        if (success) {
          this.formSubmitted();
        }
      })
    },
    async formSubmitted() {
      try {
        this.loading = true;
        const businessUpdate = {
          id: this.$route.params.id,
          name: this.name,
          enabled: this.enabled,
        };

        const result = await axios({
          method: 'patch',
          url: api.business,
          data: businessUpdate,
        });

        if (result.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Business Updated',
              icon: 'EditIcon',
              variant: 'success',
            },
          });
        } else {
          throw new Error();
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Update Failed',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      } finally {
        this.$router.push('/businesses');
      }
    },
    async getBusinessData() {
      const business = store.state.businesses.businesses.find(biz => biz.id === this.$route.params.id);
      if (business) {
        this.name = business.name;
        this.enabled = business.enabled;
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Business Doesnt Exist',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        });
        this.$router.push('/businesses');
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '~@core/assets/fonts/feather/iconfont.css';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
</style>
